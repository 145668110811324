import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/arrow_left_blue.svg'


const _hoisted_1 = {
  key: 0,
  class: "overflow-auto"
}
const _hoisted_2 = { class: "swiper-slide" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_image_and_questions = _resolveComponent("image-and-questions")
  const _component_view_profile = _resolveComponent("view-profile")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_header_feven, { white: false }, {
      left: _withCtx(() => [
        _createVNode("img", {
          src: _imports_0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
        })
      ]),
      _: 1
    }),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_view_profile, {
            match: _ctx.match,
            questions: _ctx.match.answers,
            affinity: _ctx.match.matchAffinityRate + '% / ' + _ctx.match.minimumAffinityRate + '%'
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.match.answers, (item, index) => {
                return (_openBlock(), _createBlock("div", _hoisted_2, [
                  _createVNode(_component_image_and_questions, {
                    profileQuestion: item,
                    image: item.imageUrl,
                    preview: true
                  }, null, 8, ["profileQuestion", "image"])
                ]))
              }), 256))
            ]),
            _: 1
          }, 8, ["match", "questions", "affinity"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}