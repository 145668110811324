
import { Options, Vue } from 'vue-class-component';
import { MatchClient } from '@/services/Services';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
	components: {}
})
export default class MatchProfileDetail extends Vue {

	match: OM.MatchDetailVm = new OM.MatchDetailVm();
	answers: VM.MatchQuestionAnswerVM[] = [];

	loaded: boolean = false;

    created() {
        var matchId = this.$route.params.matchIdentifier.toString();
        MatchClient.getMatchDetail(matchId, null)
        .then(x => {
            this.match = x;
			this.loaded = true;
        })
    }

}
